import {
  AuthenticatedTemplate,
  UnauthenticatedTemplate,
  useIsAuthenticated,
  useMsal,
} from '@azure/msal-react';
import React, { useEffect, useState } from 'react';
import { Route, Routes, useNavigate, Navigate } from 'react-router-dom';

import Toaster from '@components/Toaster/Toaster';
import { ListingDetails } from './pages/ListingDetails';
import { MapComponent } from './pages/Map';
import { NotFound } from './pages/NotFound';
import { Providers } from './utilities/Providers';
import ScrollToTop from '@components/ScrollToTop';
import { MapProviders } from './pages/Map/MapProviders';
import { Maintenance } from './pages/Maintenance';
import { API_LISTINGS_BASE_URL } from './config';
import { AppConfig } from './types/app-config';
import { marketplaceApiInstance } from './lib/axios';
import { MAINTENCANCE_APPCONFIG_KEY, SMOKETESTID_APPCONFIG_KEY } from './utilities/constants';
import HeaderPublic from '@components/HeaderPublic';
import { MarketRouteFilter } from './utilities/MarketRouteFilter';
import { SubmarketRouteFilter } from './utilities/SubmarketRouteFilter';
import NotFoundMetaTag from '@components/MetaTags/NotFoundMetaTag';
import { DownloadReport } from './pages/DownloadReport';
import { LogOut } from './utilities/Authentication';

const PublicApp: React.FC<any> = () => {
  // Get App Config
  const [isMaintenance, setIsMaintenance] = useState(() => {
    const sessionMaintenance = sessionStorage.getItem(MAINTENCANCE_APPCONFIG_KEY);
    return sessionMaintenance === 'true';
  });
  const [smokeTestMode, setSmokeTestMode] = useState(false);
  const isAuthenticated = useIsAuthenticated();
  const { instance } = useMsal();
  const navigate = useNavigate();

  if (isAuthenticated) {
    // Logout
    const account = instance.getActiveAccount();
    instance.logoutRedirect({ account });
  }
  useEffect(() => {
    // We cannot make use the useApiClient() hook here because the context is not ready at this point.
    // So we will make a direct axios call to get the config value.
    async function getMaintenanceConfig() {
      const res = await marketplaceApiInstance.get<AppConfig>(
        `${API_LISTINGS_BASE_URL}/appconfig?key=${MAINTENCANCE_APPCONFIG_KEY}`,
      );
      const appConfig = res?.data;
      if (appConfig?.value?.toLowerCase() === 'true') {
        setIsMaintenance(true);
        sessionStorage.setItem(MAINTENCANCE_APPCONFIG_KEY, 'true');
      } else {
        setIsMaintenance(false);
        sessionStorage.removeItem(MAINTENCANCE_APPCONFIG_KEY);
      }
    }
    async function getSmokeTestIdConfig() {
      // Enable smoke test mode only if the app is in maintenance mode
      if (isMaintenance) {
        const res = await marketplaceApiInstance.get<AppConfig>(
          `${API_LISTINGS_BASE_URL}/appconfig?key=${SMOKETESTID_APPCONFIG_KEY}`,
        );
        const appConfig = res?.data;
        const sessionValue = sessionStorage.getItem(SMOKETESTID_APPCONFIG_KEY);
        if (
          isMaintenance &&
          appConfig?.value &&
          sessionValue &&
          appConfig?.value?.toLowerCase() === sessionValue?.toLowerCase()
        ) {
          setSmokeTestMode(true);
        } else {
          setSmokeTestMode(false);
          sessionStorage.removeItem(SMOKETESTID_APPCONFIG_KEY);
        }
      } else {
        setSmokeTestMode(false);
        sessionStorage.removeItem(SMOKETESTID_APPCONFIG_KEY);
      }
    }
    getMaintenanceConfig().catch((error) => {
      console.log(error);
      // Remove maintenance state if API returns error
      setIsMaintenance(false);
      sessionStorage.removeItem(MAINTENCANCE_APPCONFIG_KEY);
    });
    getSmokeTestIdConfig().catch((error) => {
      console.log(error);
      // Remove smoke test state if API returns error
      setSmokeTestMode(false);
      sessionStorage.removeItem(SMOKETESTID_APPCONFIG_KEY);
    });
  }, []);

  return (
    <>
      <UnauthenticatedTemplate>
        <Providers>
          <MapProviders>
            {isMaintenance && !smokeTestMode ? (
              <Maintenance />
            ) : (
              <>
                <HeaderPublic smokeTestMode={smokeTestMode} />
                <ScrollToTop>
                  <Routes>
                    <Route path="/" element={<Navigate to="/map" replace={true} />} />
                    <Route path="/map" element={<MapComponent />} />
                    <Route path="/listing/:id" element={<ListingDetails />} />
                    <Route path="/not-found" element={<NotFound />} />
                    <Route path="/market/:market/:submarket" element={<SubmarketRouteFilter />} />
                    <Route path="/market/:market" element={<MarketRouteFilter />} />
                    <Route path="/download/:fileName" element={<DownloadReport />} />
                    <Route
                      path="*"
                      element={
                        <>
                          <NotFoundMetaTag />
                          <NotFound />
                        </>
                      }
                    />
                  </Routes>
                </ScrollToTop>
                <Toaster />
              </>
            )}
          </MapProviders>
        </Providers>
      </UnauthenticatedTemplate>
      <AuthenticatedTemplate>
        <LogOut />
      </AuthenticatedTemplate>
    </>
  );
};

export default PublicApp;
