import { useQuery } from '@tanstack/react-query';
import { AxiosInstance } from 'axios';

import { API_LISTINGS_BASE_URL } from '@/config';
import { Market, Submarket } from '@/types';
import { FeatureCollection, MultiPolygon, Polygon } from 'geojson';

export function getMarkets(axios: AxiosInstance) {
  return useQuery(['markets'], {
    queryFn: async () => {
      const response = await axios.get<Market[]>(`${API_LISTINGS_BASE_URL}/markets`);
      return response.data;
    },
  });
}

export function getMarketById(axios: AxiosInstance, id: string) {
  return useQuery(['market'], {
    queryFn: async () => {
      const response = await axios.get<Market[]>(`${API_LISTINGS_BASE_URL}/markets/${id}`);
      return response.data;
    },
  });
}

export function getSubmarkets(axios: AxiosInstance, marketIds: string[]) {
  return useQuery(['submarkets', marketIds], {
    queryFn: async () => {
      const response = await axios.post<Submarket[]>(
        `${API_LISTINGS_BASE_URL}/markets/submarkets`,
        {
          marketIds,
        },
      );

      return response.data;
    },
    enabled: !!marketIds,
  });
}

export function getMarketBoundaries(axios: AxiosInstance, marketIds: string[]) {
  return useQuery(['market-boundaries', marketIds], {
    queryFn: async () => {
      const response = await axios.get<FeatureCollection<Polygon | MultiPolygon>>(
        `${API_LISTINGS_BASE_URL}/markets/boundaries`,
        {
          params: {
            marketIds,
          },
        },
      );

      return response.data;
    },
    enabled: !!marketIds.length,

    // NOTE - Cache boundary data forever (next refresh).
    staleTime: Infinity,
  });
}

export function getMarketByName(axios: AxiosInstance, name: string) {
  return useQuery({
    queryKey: ['market-name'],
    queryFn: async () => {
      const response = await axios.get<Market>(`${API_LISTINGS_BASE_URL}/markets/market/${name}`);
      return response.data;
    },
  });
}

export function getSubmarketByName(
  axios: AxiosInstance,
  marketName: string,
  submarketName: string,
) {
  return useQuery({
    queryKey: ['submarket-name'],
    queryFn: async () => {
      const response = await axios.get<Market>(
        `${API_LISTINGS_BASE_URL}/markets/submarket/${marketName}/${submarketName}`,
      );
      return response.data;
    },
  });
}
