import React, { useMemo } from 'react';

import { useMapData } from '@/pages/Map/hooks/useMapData';
import { CheckboxState } from '@components/Inputs';
import { CheckboxDropdownSelectorOption } from '@components/Inputs/CheckboxDropdown/CheckboxDropdown.types';
import { Typography } from '@components/Typography';
import CheckboxDropdown from '@components/Inputs/CheckboxDropdown/CheckboxDropdown';
import { ActiveFilterQueryParam as QueryParam } from '@/types';
import { useIsAuthenticated } from '@azure/msal-react';

const AccountOwnershipFilter: React.FC = () => {
  const { accountOwnerships, updateSearchParameters } = useMapData();
  const isAuthenticated = useIsAuthenticated();

  const options = useMemo(
    () =>
      [
        { label: 'Development', value: 'DEVELOPMENT' },
        { label: 'Property Central', value: 'PROPERTY_CENTRAL' },
        { label: 'Property East', value: 'PROPERTY_EAST' },
        { label: 'Property West', value: 'PROPERTY_WEST' },
        { label: 'Shadow', value: 'SHADOW' },
      ].reduce((options, option) => {
        const optionIsChecked = accountOwnerships.includes(option.value);
        options.push({
          checkedState: optionIsChecked ? CheckboxState.CHECKED : CheckboxState.EMPTY,
          label: option.label,
          value: option.value,
        });

        return options;
      }, [] as CheckboxDropdownSelectorOption[]),
    [],
  );

  return isAuthenticated ? (
    <div className="mb-10">
      <Typography className="mb-4 text-white-100" variant="subtitle-1">
        Account Ownership
      </Typography>

      <CheckboxDropdown
        onSelect={() => {
          const selectedOptions = options
            .filter((x) => x.checkedState === CheckboxState.CHECKED)
            .map((x) => x.value);

          const value = selectedOptions.length === 0 ? null : selectedOptions.join(',');
          updateSearchParameters([[QueryParam.ACCOUNT_OWNERSHIPS, value]]);
        }}
        options={options}
        placeholder="Select Account Ownership"
      />
    </div>
  ) : (
    <></>
  );
};

export default AccountOwnershipFilter;
