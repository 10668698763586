import { useQuery } from '@tanstack/react-query';
import { AxiosInstance } from 'axios';

import { LINK_FOOTER_URL, LINK_HEADER_URL } from '@/config';

export function getHeaderHtml(axios: AxiosInstance) {
  return useQuery(['header'], {
    queryFn: async () => {
      const response = await axios.get<string>(LINK_HEADER_URL);
      return response.data;
    },
    staleTime: 2 * 60 * 60 * 1000, // 2 Hours
  });
}

export function getFooterHtml(axios: AxiosInstance) {
  return useQuery(['footer'], {
    queryFn: async () => {
      const response = await axios.get<string>(LINK_FOOTER_URL);
      return response.data;
    },
    staleTime: 2 * 60 * 60 * 1000, // 2 Hours
  });
}
