import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { downloadURI } from '@/utilities/fileDownloadHelper';
import Spinner from '@components/LoadingSpinner/Spinner';
import { useApiClient } from '@/hooks/useApiClient';

export const DownloadReport: React.FC = () => {
  const { fileName: fileId } = useParams();
  const { checkFileExists } = useApiClient();
  const [status, setStatus] = useState<'idle' | 'downloading' | 'success' | 'error'>('idle');

  useEffect(() => {
    const fileDownload = async () => {
      if (!fileId) {
        setStatus('error');
        return;
      }

      setStatus('downloading');
      try {
        const fileResponse = await checkFileExists(fileId);

        if (fileResponse?.exists && fileResponse.sasUrl) {
          downloadURI(fileResponse.sasUrl);
          setStatus('success');
        } else {
          setStatus('error');
        }
      } catch (error) {
        console.error('Error downloading the file:', error);
        setStatus('error');
      }
    };

    fileDownload();
  }, []);
  return (
    <div className="h-screen flex justify-center items-center">
      {status === 'downloading' && (
        <div className="flex flex-row items-center">
          <h1>Downloading Report...</h1>
          <Spinner />
        </div>
      )}
      {status === 'success' && <h1>Download Completed Successfully!</h1>}
      {status === 'error' && <h1>File Not Found or Unable to Download.</h1>}
    </div>
  );
};
