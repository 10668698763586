import cn from 'classnames';
import React, { Fragment } from 'react';

import { FormattedLocation, LocationCategory } from '@/types';
import { splitCamelCaseString } from '@/utilities/textHelpers';
import OverflowHiddenWrapper from '@components/OverflowHiddenWrapper';
import { Typography } from '@components/Typography';
import { dropDownListProps } from './SearchInput.types';

import './SearchInput.css';

const DropDownList: React.FC<dropDownListProps> = ({
  children,
  focusedOption,
  locationSearchData,
  onOptionSelection,
  setFocusedOption,
  dropdownHeight,
}) => {
  const dropdownListOptionClassNames = (index: number) =>
    cn(
      ['dropdown-list-option flex items-center'],
      index === focusedOption?.optionIndex ? 'bg-slate-500' : '',
    );

  return (
    <div className="dropdown-list-wrapper">
      {locationSearchData && (
        <OverflowHiddenWrapper classNames="h-full" maxHeightRem={dropdownHeight}>
          {Object.entries(locationSearchData).map(([category, locations]) => {
            const locationData = locations as FormattedLocation[];

            return (
              locationData?.length > 0 && (
                <Fragment key={category}>
                  <div className="flex items-center space-x-2 pt-2">
                    <Typography
                      className="py-1 uppercase text-slate-100 whitespace-nowrap"
                      variant="label-3">
                      {splitCamelCaseString(category)}
                    </Typography>
                    <div className="w-full border-b border-b-slate-100" />
                  </div>

                  {locationData.map((location, index) => {
                    const { category, displayName, optionIndex } = location;

                    return (
                      <div
                        key={`${category}-${displayName}`}
                        className={dropdownListOptionClassNames(optionIndex)}
                        id={`${displayName}-${optionIndex}`}
                        onMouseEnter={() => setFocusedOption(location)}
                        onMouseDown={() => onOptionSelection(category as LocationCategory, index)}>
                        <Typography variant="body-2">{displayName}</Typography>
                      </div>
                    );
                  })}
                </Fragment>
              )
            );
          })}
        </OverflowHiddenWrapper>
      )}

      {children}
    </div>
  );
};

export default DropDownList;
