import React, { useMemo } from 'react';

import { CheckboxState } from '@components/Inputs';
import CheckboxDropdown from '@components/Inputs/CheckboxDropdown/CheckboxDropdown';
import { CheckboxDropdownSelectorOption } from '@components/Inputs/CheckboxDropdown/CheckboxDropdown.types';
import { Typography } from '@components/Typography';
import { useMapData } from '@/pages/Map/hooks/useMapData';
import { ActiveFilterQueryParam as QueryParam } from '@/types';
import { useIsAuthenticated } from '@azure/msal-react';

const ClassificationFilter: React.FC = () => {
  const { classifications, updateSearchParameters } = useMapData();
  const isAuthenticated = useIsAuthenticated();

  const options = useMemo(
    () =>
      ['A', 'B', 'B+', 'B-', 'C', 'D'].reduce((options, classing) => {
        const optionIsChecked = classifications.includes(classing);
        options.push({
          checkedState: optionIsChecked ? CheckboxState.CHECKED : CheckboxState.EMPTY,
          label: classing,
          value: classing,
        });

        return options;
      }, [] as CheckboxDropdownSelectorOption[]),
    [],
  );

  return isAuthenticated ? (
    <div className="mb-4 lg:mb-6">
      <Typography className="mb-4 text-white-100" variant="subtitle-1">
        Building Class
      </Typography>

      <CheckboxDropdown
        onSelect={() => {
          const selectedOptions = options
            .filter((x) => x.checkedState === CheckboxState.CHECKED)
            .map((x) => x.value);

          const value = selectedOptions.length === 0 ? null : selectedOptions.join(',');
          updateSearchParameters([[QueryParam.CLASSIFICATIONS, value]]);
        }}
        options={options}
        placeholder="Select Class"
      />
    </div>
  ) : (
    <></>
  );
};

export default ClassificationFilter;
