import { Helmet } from 'react-helmet';

export const NotFoundMetaTag: React.FC = () => {
  return (
    <Helmet>
      <title>404 Page Not Found</title>
      <meta property="og:site_name" content="Link Logistics Property Search" />
      <meta property="og:title" content="404 Page Not Found" />
      <meta name="description" content="404 Page Not Found" />
      <meta name="og:description" content="404 Page Not Found" />
    </Helmet>
  );
};

export default NotFoundMetaTag;
