import { useApiClient } from '@/hooks/useApiClient';
import { Helmet } from 'react-helmet';
import { useEffect, useState } from 'react';
import { useMapData } from '@/pages/Map/hooks/useMapData';

export const SearchPageMetaTag: React.FC = () => {
  const [title, setTitle] = useState('Industrial Listings for Lease | Link Logistics');
  const [description, setDescription] = useState(
    'View our industrial properties available for lease',
  );
  const { marketIds } = useMapData();
  const { getMarkets } = useApiClient();

  const { data: markets } = getMarkets();

  useEffect(() => {
    const marketNamesClean = markets
      ?.filter((m) => marketIds?.includes(m.marketId))
      .map((m) => m.name)
      .join(', ');
    if (marketNamesClean) {
      setTitle(`Industrial Listings for Lease in ${marketNamesClean} | Link Logistics`);
      setDescription(`View our industrial properties available for lease in ${marketNamesClean}`);
    }
  }, [markets, marketIds]);

  return (
    <Helmet>
      <title>{title}</title>
      <meta property="og:title" content={title} />
      <meta property="og:site_name" content={'Link Logistics Property Search'} />
      <meta name="description" content={description} />
      <meta name="og:description" content={description} />
    </Helmet>
  );
};

export default SearchPageMetaTag;
