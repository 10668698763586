import React, { useState } from 'react';
import cn from 'classnames';
import { Link, useLocation } from 'react-router-dom';

import { ReactComponent as Logo } from '@images/link-logo.svg';
import { ReactComponent as LogoMobile } from '@images/link-logo-mobile.svg';
import AuthenticationMenu from './AuthenticationMenu';
import './Header.css';
import { MenuButton } from '@szhsin/react-menu';
import ContactUsModal from '@components/ContactUsModal';
import { Icon, IconName } from '@components/Icon';
import { FEATURE_FLAG_ENABLE_APP_SWITCHER } from '@/config';
import AppSwitcher from './AppSwitcher';
import { Typography } from '@components/Typography';
import DefaultMetaTag from '@components/MetaTags/DefaultMetaTag';

interface HeaderProps {
  smokeTestMode?: boolean;
}
const Header: React.FC<HeaderProps> = ({ smokeTestMode }) => {
  const location = useLocation();
  const [showContactUsModal, setShowContactUsModal] = useState(false);
  const isMapPage = location.pathname.includes('map');

  return (
    <nav className="flex items-center justify-between bg-solar-100">
      <DefaultMetaTag />
      <Link to="/">
        <Logo className="brand" />
        <LogoMobile className="brand-mobile" />
      </Link>
      {smokeTestMode && (
        <div className="smoke-test-banner">
          <Icon classNames="!w-[1.25rem] !h-[1.25rem]" name={IconName.CIRCLE_INFO} />
          <Typography variant="body-3" className="ml-[0.5rem]">
            Smoke Testing Mode
          </Typography>
        </div>
      )}
      <div className="flex items-center h-full nav-right">
        <div className="relative flex items-center h-full nav-items">
          {FEATURE_FLAG_ENABLE_APP_SWITCHER && <AppSwitcher />}
          <Link
            className={cn([
              isMapPage && 'pointer-events-none',
              'rounded lg:hover:bg-solar-200 h-[36px] w-[36px] mr-[0.875rem]',
            ])}
            to="/map">
            <Icon
              classNames="!h-[1.125rem] !w-[1.125rem] mx-2 mt-[0.575rem]"
              name={IconName.MAP_ICON}
            />
            {isMapPage && (
              <div className="absolute border border-b-[0.1875rem] border-slate-500 ml-2 mt-1 w-[1.125rem]" />
            )}
          </Link>
          <MenuButton
            onClick={() => {
              setShowContactUsModal(true);
            }}
            className="rounded lg:hover:bg-solar-200 h-[36px] w-[36px] mr-[1.1rem]">
            <Icon classNames="!h-[1.125rem] !w-[1.125rem] mx-2" name={IconName.CONTACT_ICON} />
          </MenuButton>
          {showContactUsModal && (
            <ContactUsModal
              onClose={() => {
                setShowContactUsModal(false);
              }}
            />
          )}
        </div>
        <AuthenticationMenu />
      </div>
    </nav>
  );
};

export default Header;
