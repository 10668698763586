import React from 'react';

import './HeaderPublic.css';
import DefaultMetaTag from '@components/MetaTags/DefaultMetaTag';
import { useApiClient } from '@/hooks/useApiClient';
import { Icon, IconName } from '@components/Icon';
import { Typography } from '@components/Typography';

interface HeaderProps {
  smokeTestMode?: boolean;
}

const HeaderPublic: React.FC<HeaderProps> = ({ smokeTestMode }) => {
  const { getHeaderHtml } = useApiClient();
  const { data: header } = getHeaderHtml();

  return (
    <div className="ll-header">
      <div dangerouslySetInnerHTML={{ __html: header! }}></div>
      {smokeTestMode && (
        <div className="smoke-test-banner">
          <Icon classNames="!w-[1.25rem] !h-[1.25rem]" name={IconName.CIRCLE_INFO} />
          <Typography variant="body-3" className="ml-[0.5rem]">
            Smoke Testing Mode
          </Typography>
        </div>
      )}
      <DefaultMetaTag />
    </div>
  );
};

export default HeaderPublic;
