import LandingPage from '@/pages/LandingPage';
import { InteractionStatus, InteractionType } from '@azure/msal-browser';
import { useIsAuthenticated, useMsal, useMsalAuthentication } from '@azure/msal-react';

export function Authentication() {
  const { error } = useMsalAuthentication(InteractionType.Redirect);
  const { inProgress } = useMsal();

  if (error) {
    return <>{error.message}</>;
  }

  let loadingMessage = 'Checking authentication...';

  if (inProgress === InteractionStatus.Login) {
    loadingMessage = 'Logging you in to Property Search...';
  } else if (inProgress === InteractionStatus.Logout) {
    loadingMessage = 'Logging you out of Property Search...';
  } else if (inProgress === InteractionStatus.HandleRedirect) {
    loadingMessage = 'Redirecting you to Property Search...';
  }

  return <>{loadingMessage}</>;
}

export function UserAuth() {
  const isAuthenticated = useIsAuthenticated();

  if (!isAuthenticated) {
    return <Authentication />;
  } else {
    window.location.replace(window.location.origin);
    return <LandingPage />;
  }
}

export function LogOut() {
  const { instance } = useMsal();

  const account = instance.getActiveAccount();
  instance.logoutRedirect({ account });

  const loadingMessage = 'Logging out user...';
  return <>{loadingMessage}</>;
}
