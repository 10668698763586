import { useApiClient } from '@/hooks/useApiClient';
import { Market } from '@/types';
import LoadingSpinner from '@components/LoadingSpinner/LoadingSpinner';
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { encodeSubMarket } from '@/pages/ListingDetails/ListingDetails.utils';

export function SubmarketRouteFilter() {
  const navigate = useNavigate();
  const params = useParams();
  const { getSubmarketByName } = useApiClient();
  const marketName = encodeSubMarket(params.market as string);
  const submarketName = encodeSubMarket(params.submarket as string);
  const [market, setMarket] = useState<Market | undefined>();

  const {
    data: mData,
    isLoading,
    isFetching,
    isError,
  } = getSubmarketByName(marketName, submarketName);

  useEffect(() => {
    if (!marketName || !submarketName) {
      navigate('/map', { replace: true });
      return;
    }
    setMarket(mData);
  }, [mData, isFetching, isLoading]);

  useEffect(() => {
    if (isError) {
      navigate('/map', { replace: true });
      return;
    }
    if (!isLoading && !isFetching) {
      let url = '/map';
      if (market) {
        url = `/map?market_id=${market.parentId}&submarket_ids=${market.marketId}`;
      }
      navigate(url, { replace: true });
      return;
    }
  }, [market, isError]);

  return (
    <div className="flex items-center justify-center w-full p-10">
      <LoadingSpinner />
    </div>
  );
}
