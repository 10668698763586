import { Helmet } from 'react-helmet';

export const DefaultMetaTag: React.FC = () => {
  const title = 'Property Search - Link Logistics';
  return (
    <Helmet>
      <title>{title}</title>
      <meta property="og:site_name" content={title} />
      <meta property="og:title" content={title} />
    </Helmet>
  );
};

export default DefaultMetaTag;
