import { useEffect, useMemo, useState } from 'react';
import { Navigate, useLocation, useNavigate, useParams } from 'react-router-dom';

import { useApiClient } from '@/hooks/useApiClient';
import { useToast } from '@/hooks/useToast';
import { useUserFavorites } from '@/hooks/useUserFavorites';
import { TemplateAttributeKey, TemplateTypes } from '@/types';
import FooterPublic from '@components/FooterPublic/FooterPublic';
import LoadingSpinner from '@components/LoadingSpinner/LoadingSpinner';
import RemoveSavedModal from '@components/RemoveSavedModal';
import { ShareListingModal } from '@components/ShareListingModal';

import Button, { ButtonVariant } from '@components/Button';
import { Icon, IconName } from '@components/Icon';
import ListingActions from './ListingActions';
import './ListingDetails.css';
import ListingDetailsContent from './ListingDetailsContent-component';
import { encodeSubMarket, getKeyValue, isInViewport } from './ListingDetails.utils';
import ListingPageMetaTag, {
  ListingPageMetaTagProps,
} from '@components/MetaTags/ListingPageMetaTag';
import Footer from '@components/Footer';
import useIsPublicApp from '@/hooks/useIsPublicApp';

export const ListingDetails = () => {
  const [showRemoveFavoritesModal, setShowRemoveFavoritesModal] = useState<boolean>(false);
  const [showShareModal, setShowShareModal] = useState<boolean>(false);
  const [isBottom, setIsBottom] = useState<boolean>(false);
  const [downloading, setDownloading] = useState<boolean>(false);
  const [metadata, setMetadata] = useState<ListingPageMetaTagProps>();

  const { getListingData, getFlyer, updateListingViewCount } = useApiClient();
  const location = useLocation();
  const navigate = useNavigate();
  const params = useParams();
  const { addFavorite, removeFavorites, favorites, isFavorite } = useUserFavorites();
  const { addToast } = useToast();
  const { mutate: updateListingViewCountMutation } = updateListingViewCount();
  const isPublicApp = useIsPublicApp();

  const propertyID = params.id!;
  // Should return true if URL shared or open in new browser tab
  const isRouteHistoryStackEmpty = location.key === 'default';

  // TODO Need to handle error state
  const { data, isLoading, isFetching, isError } = getListingData(TemplateTypes.DetailedListing, [
    propertyID,
  ]);
  const propertyType = data ? data[0].propertyType : '';
  // console.log('data', data);

  const isSaved = useMemo(() => isFavorite(propertyID), [favorites]);

  if (data) {
    const isVisibleListing = getKeyValue(data[0], TemplateAttributeKey.IsVisible);
    const market = encodeSubMarket(getKeyValue(data[0], TemplateAttributeKey.Market) as string);
    const SubMarket = encodeSubMarket(
      getKeyValue(data[0], TemplateAttributeKey.Submarket) as string,
    );
    if (isVisibleListing === 'false') {
      navigate(`/market/${market}/${SubMarket}`);
    }
  }

  const handleAddingFavoriteListing = (id: string) => {
    addFavorite(id);
  };

  const handleRemovingFavoriteListing = (id: string) => {
    removeFavorites([id]);
    setShowRemoveFavoritesModal(false);
  };

  const handleDownloadFlyer = async () => {
    setDownloading(true);
    const data = await getFlyer(propertyID);
    const link = document.createElement('a');
    link.href = URL.createObjectURL(data);
    link.download = `flyer-${propertyID}-${new Date().getTime()}.pdf`;
    link.dispatchEvent(new MouseEvent('click'));

    addToast({
      id: `download-flyer-${new Date().getTime()}`,
      title: `Success`,
      description: 'Listing Flyer Downloaded',
      type: 'success',
    });
    setDownloading(false);
  };

  useEffect(() => {
    if (data) {
      const primaryAttributes = data[0]?.children[0]?.attributes;
      const specAttributes = data[0]?.children[2]?.attributes;
      const name = primaryAttributes.filter((a) => a.key === 'NAME')[0]?.value;
      const address = primaryAttributes.filter((a) => a.key === 'FULL_ADDRESS')[0]?.value;
      const market = primaryAttributes.filter((a) => a.key === 'MARKET')[0]?.value;
      const size = specAttributes.filter(
        (a) => a.key === 'UNIT_OFFICE_SQUARE_FOOTAGE_OR_BUILD_TO_SUIT',
      )[0]?.value;

      setMetadata({
        name,
        address,
        location: market,
        size,
        seoJsonMarkup: data[0]?.seoJsonContext,
      });
    }
  }, [data]);

  useEffect(() => {
    const handleScroll = () => {
      const el = document.getElementById('contactBannerBottom');
      if (isInViewport(el)) {
        setIsBottom(true);
      } else {
        setIsBottom(false);
      }
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  useEffect(() => {
    updateListingViewCountMutation({
      listingId: propertyID,
      listingMetricType: 0,
    });
  }, []);

  if (isError || (!isLoading && data.length === 0)) {
    return <Navigate to="/not-found" />;
  }

  return (
    <>
      <ListingPageMetaTag
        name={metadata?.name}
        address={metadata?.address}
        location={metadata?.location}
        size={metadata?.size}
        seoJsonMarkup={metadata?.seoJsonMarkup}
      />
      <div className="overflow-y-auto property-listing-page">
        {isFetching || isLoading ? (
          <div className="flex items-center justify-center w-full p-10">
            <LoadingSpinner />
          </div>
        ) : (
          <div>
            <div className="property-listing-header">
              <div className="flex items-center max-w-[70.625rem] w-full  mx-6">
                {!isRouteHistoryStackEmpty && (
                  <div className="back-button-wrapper">
                    <Button
                      Icon={<Icon name={IconName.CHEVRON_LEFT} />}
                      label="Back"
                      onClick={() => navigate(-1)}
                      variant={ButtonVariant.NO_FILL}
                    />
                  </div>
                )}
                <ListingActions
                  propertyID={propertyID}
                  isRouteHistoryStackEmpty={isRouteHistoryStackEmpty}
                  isSaved={isSaved}
                  setShowShareModal={setShowShareModal}
                  setShowRemoveFavoritesModal={setShowRemoveFavoritesModal}
                  handleAddingFavoriteListing={handleAddingFavoriteListing}
                  handleDownloadFlyer={handleDownloadFlyer}
                  disableDownloadFlyerButton={downloading}
                />
              </div>
            </div>
            <ListingDetailsContent data={data} isBottom={isBottom} />
            <RemoveSavedModal
              category="favorite"
              onCancel={() => setShowRemoveFavoritesModal(false)}
              onRemove={() => handleRemovingFavoriteListing(propertyID)}
              show={showRemoveFavoritesModal}
              subtext="Removing these listing(s) from Favorites will also remove them from any saved Collections."
            />{' '}
            {showShareModal && (
              <ShareListingModal
                listingId={propertyID}
                propertyType={propertyType}
                show={showShareModal}
                onClose={() => {
                  setShowShareModal(false);
                }}
              />
            )}
          </div>
        )}
        {isPublicApp ? <FooterPublic /> : <Footer />}
      </div>
    </>
  );
};

export default ListingDetails;
